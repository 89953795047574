<template>
<div>
    <b-navbar class='nav-on-top' toggleable="lg" type="dark" variant="dark" >
        <b-navbar-brand href="#" >
            <img src="../assets/logo.png" alt="logo" style="max-height: 20px;" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse is-nav id="nav_collapse" class="menu-bar">

            <b-navbar-nav>
                
                <!-- start aici inseram restul de elemente din meniu -->
                <b-nav-item href='#/clubs' class='clubs'> Clubs </b-nav-item>
                <b-nav-item href='#/clubstables' class='clubstables'> Clubs Tables </b-nav-item>
                <b-nav-item href='#/atmosphere_models' class='atmosphere_models'> Atmosphere models </b-nav-item>
                <b-nav-item href='#/guns_and_tanks' class='guns_and_tanks'> Guns and tanks </b-nav-item>
                <b-nav-item href='#/limos' class='limos'> Limos </b-nav-item>
                <b-nav-item href='#/helicopters' class='helicopters'> Helicopters </b-nav-item>
                <b-nav-item href='#/countries' class='countries'> Countries </b-nav-item>
                <b-nav-item href='#/creditcard' class='creditcard'> CreditCard </b-nav-item>
                <b-nav-item href='#/hosts' class='hosts'> Hosts </b-nav-item>
                <b-nav-item href='#/viphosts' class='viphosts'> VipHosts </b-nav-item>
                <b-nav-item href='#/vipbodyguards' class='vipbodyguards'> VipBodyguards </b-nav-item>
                <b-nav-item href='#/reservations' class='reservations'> Reservations </b-nav-item>
                <b-nav-item href='#/vouchers' class='vouchers'> Vouchers </b-nav-item>
                <b-nav-item href='#/vouchers_report' class='vouchers_report'> Vouchers report </b-nav-item>
                
                <b-nav-item href='#/users' class='users'> Users </b-nav-item>

                <!-- end aici inseram restul de elemente din meniu -->
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown text="Utilizatori si drepturi" right>
                    <b-dropdown-item href="#/utilizatori">Utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/grupuri-utilizatori">Grupuri utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/categorii-drepturi">Categorii drepturi</b-dropdown-item>
                    <b-dropdown-item href="#/drepturi">Drepturi</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item @click="change_password()"> Schimbare parola</b-nav-item>

                <b-nav-item @click="onLogout()"  right> Iesire cont</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
    <change-password-dialog ref="changePassDlg"></change-password-dialog>
</div>

</template>

<script>

import settings from '@/backend/LocalSettings';
import ChangePassword_dialog from '@/pages/ChangePassword_dialog';

export default {
    name: 'Login',
    data () {
        return {
            user_type: ''
        }
    },
    components: {
        'change-password-dialog': ChangePassword_dialog
    },
    methods:
        {
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                }
                else
                {
                    this.$router.push("/");
                }
            },

            change_password: function(){
                this.$refs['changePassDlg'].show_me();
            },

            handleSelect: function(item)
            {
                console.log(item);
            },

            async onLogout()
            {
                settings.logout();
                this.$router.push("/");
            }
        },
    mounted()
    {
        this.user_type    = settings.get_user_type();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.nav-on-top{
    z-index:1000;
}

.navbar{
    margin-top: 20px;
}

.bg-dark{
    background-color: #152e33 !important;
}

.navbar-dark .navbar-nav .nav-link{
    color: #44b0cc;
    text-transform: uppercase;
    font-size: 12px;
}

.navbar-dark .active .nav-link{
    background-color: #0d7aa3;
    color: white;
}

</style>
